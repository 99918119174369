import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, styled } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import ProjectLibrary from "../../../assets/images/NewImages/project-library-illustration.png";
import LiteratureReview from "../../../assets/images/NewImages/literature-review-illustration.png";
import TextEditor from "../../../assets/images/NewImages/text-editor-illustration.png";
import SearchImage from "../../../assets/images/NewImages/search-illustration.png";
import AskResearch from "../../../assets/images/NewImages/ask-researchpal-illustration.png";
import TextCitation from "../../../assets/images/NewImages/text-citations-illustration.png";

import { TypeAnimation } from "react-type-animation";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";
import { addBlog } from "./BlogSlice";
import { blogPosts } from "./blogPosts";
// import { blogPosts } from "../blogss/blogPosts";
import { useDispatch } from "react-redux";
// Helper function to convert title to slug
export const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
};

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  background: "#fff",
  // marginTop: "10vh",
  padding: "24px 30px 0 30px",
  [theme.breakpoints.down("md")]: {
    padding: "0 10px 64px 10px",
  },
}));
const CustomCard = styled(Card)({
  //   margin: "0 auto",
  maxWidth: "340px",
  //   padding: "8px",
  borderRadius: "8px",
  // height: "500px",

  overflow: "hidden", // To ensure the image doesn't overflow
  "&:hover .zoom-image": {
    transform: "scale(1.1)", // Zoom effect on hover
    backgroundPosition: "50%", // Control background positioning
  },
});

const ImageStyle = styled(CardMedia)({
  height: 200,
  transition: "transform 0.8s cubic-bezier(.075, .82, .165, 1)",
});

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "22px",
  marginBottom: "8px",
});
const CardText = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "15px",
  //justify text to justified
  lineHeight: "24px",
});

const TypeText = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Raleway",
  fontWeight: 600,
  textTransform: "uppercase",
  color: "#6E7A84",
});

function Blog() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedBlog, setSelectedBlog] = useState(null);

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });

  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  const handleCardClick = (item) => {
    setSelectedBlog(item);
    localStorage.setItem("selectedBlog", JSON.stringify(item));
    dispatch(addBlog(item));
    localStorage.setItem("Blogs", item);
    navigate(`/blog/${item.id}`);
  };

  return (
    <Box>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <Typography
          sx={{
            color: "#14224B",
            fontWeight: 400,
            fontFamily: "Righteous",
            fontSize: "32px",
            textAlign: "center",
            mb: "24px",
          }}
        >
          Blog
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            cursor: "pointer",
          }}
        >
          {blogPosts.map((item, index) => (
            <Box key={index} onClick={() => handleCardClick(item)}>
              <CustomCard>
                <Box sx={{ overflow: "hidden" }}>
                  <ImageStyle
                    className="zoom-image"
                    image={item.image}
                    title="preview"
                  />
                </Box>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    height: "100%",
                    marginBottom: 0.5,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <Title sx={{ fontSize: "12px", color: "#4A90E2", mt: 1 }}>
                    {item.category}
                  </Title>
                  <Title sx={{ "&:hover": { color: "#1AB394" } }}>
                    {item.title.length > 25
                      ? `${item.title.substring(0, 25)}...`
                      : item.title}
                  </Title>
                  <CardText>
                    {" "}
                    {item.metaText.length > 110
                      ? `${item.metaText.substring(0, 110)} [...]`
                      : item.metaText}
                  </CardText>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <TypeText>
                      <li>{item.postOn}</li>
                    </TypeText>

                    <TypeText>
                      <li>{item.read}</li>
                    </TypeText>
                  </Box>
                </CardContent>
              </CustomCard>
            </Box>
          ))}
        </Box>

        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </RootStyle>
      <Box sx={{ mt: 10 }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default Blog;
