import { blog1 } from "./blog1";
import { blog2 } from "./blog2";
import { blog3 } from "./blog3";
import { blog4 } from "./blog4";
import { blog5 } from "./blog5";
import { blog6 } from "./blog6";





export const blogPosts = [
    
  blog6,
  blog5,
  blog4,
  blog3,
  blog2,
  blog1,

  ];