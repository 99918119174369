import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  blog:[],
  
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {

    addBlog:(state,action)=>{
      state.blog=action.payload;
    },
    clearBlog:(state,action)=>{
      state.blog=[];
    },
   
  },
});

export const {

  addBlog,
  clearBlog,
} = blogSlice.actions;

export default blogSlice.reducer;
