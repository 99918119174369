import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DialogContent, InputLabel, MenuItem, Select } from "@mui/material";
import { FormControl, FormControlLabel, styled, Radio } from "@mui/material";
import { RadioGroup, Stack, Tooltip, Typography, Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TypeAnimation } from "react-type-animation";
import Toast from "../../components/Toast";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuery,
  clearAllLitReview,
  clearLitReview,
  clearQuery,
  setPasteContent,
  showToast,
  hideToast,
  abortReviewFetch,
  abortCancelFetch,
  clearLiterature,
  setReferencesLLM,
} from "./reviewSlice";
import { setLitDomain, setSelectedFormat, setValue } from "./reviewSlice";
import { clrTracker, getReview, handleSession } from "./reviewSlice";
import { addProject, addProjectTitle } from "./reviewSlice";
import LitReviewImg from "../../assets/images/lit-review.svg";
import BtnClear from "../../components/BtnClear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchField from "../../components/SearchField";
import { clearRefs } from "../references/refsSlice";
import Loader from "../loader";
import { addProgress, clrProgress } from "../loader/LoaderSlice";
import Repo from "../../repo/Repo";
import { InfoOutlined, ThumbUp } from "@mui/icons-material";
import { Ratings } from "../../utils/Ratings";
import moment from "moment";
import {
  LitReference,
  saveLiteratureRatings,
  sendErrorMail,
} from "../../apiservice";
import ReactGA from "../../Analytics";
import { useLocation, useNavigate } from "react-router-dom";
import { clearUser } from "../Admin/userSlice";
import { Domains } from "../authentication/sign up/DomainsList";
import journalCS from "./Journals/JournalCS";
import journalMed from "./Journals/JournalMed";
import journalHum from "./Journals/JournalHum";
import journalBusiness from "./Journals/JournalBusiness";
import publicationCS from "./Publications/PublicationCS";
import publicationMed from "./Publications/PublicationMed";
import publicationHum from "./Publications/PublicationHum";
import publicationBusiness from "./Publications/PublicationBusiness";
import publications, { publicationArr } from "./Publications/Publications";
import { clearAllInsights } from "../paper insights/insightsSlice";
import { clearProject } from "../projects/projectSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReactHtmlParser from "react-html-parser";
import GuidelineDialog from "./GuidelineDialog";
import LitDialog from "./LitDialog";
const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "12px",
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});

const RootStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#FFFFFF",
  minHeight: "100vh",
  maxHeight: "100vh",
  overflowY: "scroll",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
  padding: "16px 32px",
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
  },
}));
const ImgBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

const Btn = styled(Button)({
  textTransform: "none",
  background: "#3535351A !important",
  color: "#353535CC",
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "12px",
  boxShadow: "none !important",
  "&:hover": {
    color: "#353535",
  },
});

const text =
  "Option “Web” will generate literature review based on research papers from the web and option “Library” will generate review based on your uploaded library documents.";

function LiteratureDialog() {
  const mediaQuery = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [publicationNew, setPublicationNew] = useState("");
  const [selectedJournal, setSelectedJournal] = useState([]);
  const [selectedJournalNames, setSelectedJournalNames] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState("");
  const [litOpenDialog, setLitOpenDialog] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  // const [toastShown, setToastShown] = useState(false);
  // const [toastShown, setToastShown] = useState(
  //   JSON.parse(localStorage.getItem("toastShown")) || false
  // );
  const { loading, projects, projectTitle } = useSelector(
    (state) => state.review
  );
  const { error, tracker, isSessionExpired, myTimer } = useSelector(
    (state) => state.review
  );
  const { progress } = useSelector((state) => state.loader);
  const va = useSelector((state) => state.review);
  const { user } = useSelector((state) => state.user);
  const domain = useSelector((state) => state.review.domain);
  const selectedFormat = useSelector((state) => state.review.selectedFormat);
  const value = useSelector((state) => state.review.value);
  const toastShown = useSelector((state) => state.review.toastShown);
  const projectId = localStorage.getItem("projectId");
  const prTitle = localStorage.getItem("projectTitle");
  let currPro = projects.find((i) => i.title == prTitle);
  let query = currPro?.query || "";
  let sourceArr = currPro?.sourceArr || [];
  const references = useSelector((state) => state.review.referencesLLM);
  console.log("Source Arr:", sourceArr);
  let literature = currPro?.literature || "";
  // if (literature) {
  //   Toast("success", "Literature Review generated");
  // }
  let lastString = currPro?.lastString || "";
  let libraryReferences = currPro?.libraryReferences || [];
  const userDomain = Domains.includes(user.domain) ? user.domain : "Other";
  const [state, setState] = useState({
    format: false,
    showAlert: false,
    ratings: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);
  // const [references, setReferences] = useState({});
  console.log("LLM References", references);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      sx: {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Set your desired box shadow here
      },
    },
  };

  useEffect(() => {
    if (!domain) {
      dispatch(setLitDomain(userDomain));
    }
  }, [dispatch, userDomain, domain]);

  useEffect(() => {
    if (!isSessionExpired) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Literature Review",
      });
    }
    if (isSessionExpired) {
      clearSession();
    }
  }, [isSessionExpired]);
  useEffect(() => {
    if (!loading) {
      setState((prev) => ({ ...prev, showAlert: false }));
    }
  }, [loading]);

  useEffect(() => {
    if (!currPro) {
      dispatch(addProject(prTitle));
    }
    if (!projectTitle) {
      dispatch(addProjectTitle(prTitle));
    }
    if (projectTitle != prTitle) {
      dispatch(addProjectTitle(prTitle));
    }
  }, [prTitle, projectTitle, currPro]);

  useEffect(() => {
    if (tracker == 150 && loading) {
      setState((prev) => ({ ...prev, showAlert: true }));
    }
  }, [progress]);

  useEffect(() => {
    if (value === "library") {
      getFiles();
    }
  }, [value]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(addProgress(1));
    }, 500);
    localStorage.setItem("timer", timer);
    if (!myTimer) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [myTimer]);

  // useEffect(() => {
  //   if (literature && !toastShown) {
  //     Toast("success", "Literature Review generated");
  //     setToastShown(true);
  //   } else if (!literature) {
  //     setToastShown(false);
  //   }
  // }, [literature, toastShown]);

  // useEffect(() => {
  //   if (currPro?.literature && !toastShown) {
  //     Toast("success", "Literature Review generated");
  //     setToastShown(true);
  //     localStorage.setItem("toastShown", true);
  //   } else if (!literature) {
  //     setToastShown(false);
  //     localStorage.setItem("toastShown", false);
  //   }
  // }, [literature, toastShown]);

  // useEffect(() => {
  //   if (currPro?.literature && !toastShown) {
  //     Toast("success", "Literature Review generated");
  //     dispatch(showToast());
  //   } else if (!currPro?.literature) {
  //     dispatch(hideToast());
  //   }
  // }, [currPro, toastShown, dispatch]);

  const clearSession = () => {
    localStorage.clear();
    dispatch(handleSession());
    dispatch(clearUser());
    navigate("/");
  };

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const handleLitDialogClose = () => {
    setLitOpenDialog(false);
  };

  const handlePublicationChange = (event) => {
    const publicationNew = event.target.value;
    setPublicationNew(publicationNew);
    setSelectedJournal([]);
    setSelectedJournalNames([]);
  };

  const getPublications = (domain) => {
    switch (domain) {
      case "Computer Science":
        return publicationCS;
      case "Medical & Health Sciences":
        return publicationMed;
      case "Humanities and Social Sciences":
        return publicationHum;
      case "Business Studies":
        return publicationBusiness;
      default:
        return publicationArr;
    }
  };

  // Function to filter journals based on the selected domain and publication
  // const getJournals = (domain, publicationKey) => {
  //   let journals = [];
  //   if (state.domain === "Computer Science" && publicationNew) {
  //     journals = journalCS.filter(
  //       (journal) => journal.pubKey === publicationNew
  //     );
  //   } else if (state.domain === "Medical & Health Sciences" && publicationNew) {
  //     journals = journalMed.filter(
  //       (journal) => journal.pubKey === publicationNew
  //     );
  //   } else if (
  //     state.domain === "Humanities and Social Sciences" &&
  //     publicationNew
  //   ) {
  //     journals = journalHum.filter(
  //       (journal) => journal.pubKey === publicationNew
  //     );
  //   } else if (state.domain === "Business Studies" && publicationNew) {
  //     journals = journalBusiness.filter(
  //       (journal) => journal.pubKey === publicationNew
  //     );
  //   } else {
  //     journals = [];
  //   }

  //   return journals;
  // };

  const getJournals = (publicationKey) => {
    let journals = [];
    switch (domain) {
      case "Computer Science":
        if (publicationNew) {
          journals = journalCS.filter(
            (journal) => journal.pubKey === publicationNew
          );
        }
        break;
      case "Medical & Health Sciences":
        if (publicationNew) {
          journals = journalMed.filter(
            (journal) => journal.pubKey === publicationNew
          );
        }
        break;
      case "Humanities and Social Sciences":
        if (publicationNew) {
          journals = journalHum.filter(
            (journal) => journal.pubKey === publicationNew
          );
        }
        break;
      case "Business Studies":
        if (publicationNew) {
          journals = journalBusiness.filter(
            (journal) => journal.pubKey === publicationNew
          );
        }
        break;
      default:
        journals = [];
    }

    return journals;
  };

  // const handleJournalChange = (event) => {
  //   const journalKey = event.target.value;
  //   let updatedSelectedJournals = Array.isArray(journalKey)
  //     ? journalKey
  //     : [journalKey];

  //   // Check if the maximum limit has been exceeded
  //   let maxJournals;
  //   switch (state.selectedFormat) {
  //     case "short":
  //       maxJournals = 6;
  //       break;
  //     case "medium":
  //       maxJournals = 12;
  //       break;
  //     case "long":
  //       maxJournals = 20;
  //       break;
  //     default:
  //       maxJournals = 6;
  //   }

  //   if (updatedSelectedJournals.length > maxJournals) {
  //     Toast(
  //       "error",
  //       `Can't select more than ${maxJournals} journals for ${state.selectedFormat} format literature review`
  //     );
  //     // toast.error(`You can select not more than ${maxJournals} journals`);
  //     // If maximum limit exceeded, don't update the selected journals
  //     return;
  //   }

  //   setSelectedJournal(updatedSelectedJournals);
  // };

  const handleJournalChange = (event) => {
    const journalKeys = event.target.value;
    let updatedSelectedJournals = Array.isArray(journalKeys)
      ? journalKeys
      : [journalKeys];

    // Check if the maximum limit has been exceeded
    let maxJournals;
    switch (selectedFormat) {
      case "short":
        maxJournals = 6;
        break;
      case "medium":
        maxJournals = 12;
        break;
      case "long":
        maxJournals = 20;
        break;
      default:
        maxJournals = 6;
    }

    if (updatedSelectedJournals.length > maxJournals) {
      Toast(
        "error",
        `Can't select more than ${maxJournals} journals for ${selectedFormat} format literature review`
      );
      // If maximum limit exceeded, don't update the selected journals
      return;
    }

    // Extract names of selected journals
    const selectedJournalNames = updatedSelectedJournals
      .map((key) => {
        const journal = getJournals(state.domain, selectedPublication).find(
          (journal) => journal.key === key
        );
        return journal ? journal.name : null;
      })
      .filter(Boolean);

    // Set selected journal keys and names separately
    setSelectedJournal(updatedSelectedJournals);
    setSelectedJournalNames(selectedJournalNames);
  };

  // const handleRadio = (event) => {
  //   setValue(event.target.value);
  //   if (event.target.value === "web") {
  //     dispatch(clearLitReview(prTitle));
  //     setSelectedFiles([]);
  //     dispatch(clearQuery(prTitle));
  //   } else if (event.target.value === "library") {
  //     dispatch(clearLitReview(prTitle));
  //     setSelectedFiles([]);
  //     dispatch(clearQuery(prTitle));
  //   }
  // };

  const handleRadio = (event) => {
    dispatch(setValue(event.target.value));
    if (event.target.value === "web") {
      dispatch(clearLitReview(prTitle));
      setSelectedFiles([]);
      dispatch(clearQuery(prTitle));
    } else if (event.target.value === "library") {
      dispatch(clearLitReview(prTitle));
      setSelectedFiles([]);
      dispatch(clearQuery(prTitle));
    }
  };

  const handleInput = (e) => {
    let { value } = e.target;

    // Limit to a maximum of 450 characters
    if (value.length > 250) {
      value = value.slice(0, 250);
    }

    // Prevent further typing by updating the input value
    e.target.value = value;

    // Dispatch the final value with title
    dispatch(addQuery({ value, title: prTitle }));
  };

  // const handleInput = (e) => {
  //   let { value } = e.target;
  //   const words = value.trim().split(/\s+/);

  //   // Limit to a maximum of 60 words
  //   if (words.length > 60) {
  //     value = words.slice(0, 60).join(" ");
  //   }

  //   // Check if the total character count exceeds 400
  //   if (value.length > 450) {
  //     // Trim to a maximum of 400 characters
  //     value = value.slice(0, 450);
  //   }

  //   // Split again after potential modifications
  //   const newWords = value.trim().split(/\s+/);

  //   // If the last word exceeds 12 characters, trim it
  //   if (newWords.length === 60 && newWords[59].length > 12) {
  //     // Get the first 59 words
  //     const trimmedWords = newWords.slice(0, 59);

  //     // Trim the 60th word to a maximum of 12 characters
  //     const lastWord = newWords[59].slice(0, 12);

  //     // Join them together to form the final value
  //     value = [...trimmedWords, lastWord].join(" ");
  //   }

  //   // Prevent further typing by updating the input value
  //   e.target.value = value;

  //   // Dispatch the final value with title
  //   dispatch(addQuery({ value, title: prTitle }));
  // };

  const handleDomainInput = (e) => {
    // const { name, value } = e.target;
    //if value is chemistry then set value as "Other"

    // setState((prev) => ({ ...prev, [name]: value }));
    const { value } = e.target;
    dispatch(setLitDomain(value));

    setPublicationNew("");
    setSelectedJournal([]);
    setSelectedJournalNames([]);
  };
  const handleShow = () => {
    setShow((prev) => !prev);
  };
  //get files key
  const getFiles = async () => {
    try {
      // setLoading(true);
      let { data } = await Repo.research({
        request: {
          method: "getPdfData",
          data: {
            researchProjectId: projectId,
          },
        },
      });
      // setLoading(false);
      if (data?.response?.status?.statusCode === 200) {
        let filesData = data?.response?.data?.projObj;

        // convert to array with keys and titles
        let arr = Object.keys(filesData).map((key) => ({
          key: key,
          title: filesData[key].title,
        }));
        console.log(arr);
        setFilesData(arr);
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      // setLoading(false);
      console.log(err);
      Toast("error", err);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  const searchAbstracts = async () => {
    dispatch(clrProgress());
    dispatch(clrTracker(0));
    if (!query) {
      Toast("error", "Enter your query");
      return;
    }

    let myToken = localStorage.getItem("token");
    let validToken = "";
    if (myToken && myToken != "undefined") {
      validToken = myToken;
    }
    // if (!state.selectedFormat) {
    //   setState((prev) => ({ ...prev, format: true }));
    //   return;
    // }
    if (!selectedFormat) {
      dispatch(setSelectedFormat("short"));
      return;
    }
    dispatch(addProgress(0));

    let info = {};
    // let domain = state.domain == "Other" ? "Other" : state.domain;
    let selectedDomain = domain === "Other" ? "Other" : domain;
    if (value === "library") {
      if (selectedFiles.length < 1) {
        Toast("error", "Please select atleast 1 file");
        return;
      }
      info = {
        query: `${query}`,
        projectId: projectId,
        s3Files: selectedFiles,
        libraryLiteratureReview: true,
        emailAccess: localStorage.getItem("email"),
        token: validToken,
        length: selectedFormat,
      };

      // await dispatch(getReview({ info, id: projectId, value }));
      // dispatch(clearRefs());

      const timeoutId = setTimeout(() => {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Literature Review, ${value}`,
          error: "1 minute",
          query: query,
          delayInResponse: true,
        });
      }, 61000);

      try {
        const response = await dispatch(
          getReview({ info, id: projectId, value })
        );
        clearTimeout(timeoutId);
        const data = response.payload;
        console.log("dataLit", data);

        if (
          value === "library" &&
          data.data.response === "Something went wrong!"
        ) {
          // Send error email if the response contains an error and it's the 'library' case
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Literature Review, ${value}`,
            error: data.data.response,
            query: query,
          });
        } else if (value === "library" && data.data.error) {
          if (
            data.data.error ===
            "Literature reviews limit reached. Please upgrade your subscription for more."
          ) {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Literature Review, ${value}`,
              error: data.data.error,
              query: query,
              limitReachedError: true,
            });
          } else {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Literature Review, ${value}`,
              error: data.data.error,
              query: query,
            });
          }
        }

        dispatch(clearRefs());
      } catch (error) {
        clearTimeout(timeoutId);
        if (value === "library") {
          // Send error email if an actual error occurs only for 'library'
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Literature Review, ${value}`,
            error: error.message,
            query: query,
          });
        }
      }
    } else if (value === "web") {
      info = {
        query: query,
        // projectResearchWebQuery: true,
        //if user have a pro plan

        title: prTitle,
        email: localStorage.getItem("email"),
        length: selectedFormat,
        emailAccess: localStorage.getItem("email"),
        domain: selectedDomain,
        token: validToken,
        //
        projectResearchWebQuery: true,
      };

      // if (user.subscription.planName === "pro") {
      //   let defaultPublication = "";
      //   let defaultJournals = [];
      //   let defaultJournalNames = [];

      //   switch (domain) {
      //     case "Computer Science":
      //       defaultPublication = "sasm";
      //       defaultJournals = ["s", "a", "s", "m"];
      //       defaultJournalNames = ["s", "a", "s", "m"];
      //       break;
      //     case "Medical & Health Sciences":
      //       defaultPublication = "pm";
      //       defaultJournals = ["p", "m"];
      //       defaultJournalNames = ["p", "m"];
      //       break;
      //     case "Humanities and Social Sciences":
      //       defaultPublication = "ssm";
      //       defaultJournals = ["s", "s", "m"];
      //       defaultJournalNames = ["s", "s", "m"];
      //       break;
      //     case "Business Studies":
      //       defaultPublication = "ssm";
      //       defaultJournals = ["s", "s", "m"];
      //       defaultJournalNames = ["s", "s", "m"];
      //       break;
      //     case "Chemistry":
      //       defaultPublication = "sasm";
      //       defaultJournals = ["s", "a", "s", "m"];
      //       defaultJournalNames = ["s", "a", "s", "m"];
      //       break;
      //     default:
      //       defaultPublication = "sasm";
      //       defaultJournals = ["s", "a", "s", "m"];
      //       defaultJournalNames = ["s", "a", "s", "m"];
      //   }

      //   info.journals =
      //     (selectedJournal.length > 0 && selectedJournal) || defaultJournals;
      //   info.journalNames =
      //     (selectedJournal.length > 0 && selectedJournalNames) ||
      //     defaultJournalNames;
      //   info.publication = publicationNew || defaultPublication;
      //   info.proLiteratureReview = true;
      // } else {
      //   info.projectResearchWebQuery = true;
      // }

      // console.log(info);
      // await dispatch(getReview({ info, id: projectId, value }));
      // console.log("currPro?.literature:", currPro?.literature);
      // if (currPro?.literature) {
      //   // Toast("success", "Literature Review generated !");
      // }
      // dispatch(clearRefs());

      const timeoutId = setTimeout(() => {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Literature Review, ${selectedDomain}, ${selectedFormat}`,
          error: "1 minute",
          query: query,
          delayInResponse: true,
        });
      }, 61000);

      try {
        const response = await dispatch(
          getReview({ info, id: projectId, value })
        );
        clearTimeout(timeoutId);
        const data = response.payload;
        console.log("dataLit", data);

        // if (!data.data.response.includes("<a href=")) {
        //   // Call LitReference API if anchor tags are not found
        //   Toast("info", "Verfying References!!");
        //   const litData = {
        //     email: localStorage.getItem("email"),
        //     token: validToken,
        //     references: Object.values(data.data.sourceObj), // List of references from sourceObj
        //     verifyReferences: true,
        //   };
        //   try {
        //     const response = await LitReference(litData);
        //     Toast("success", "References Verified!!");
        //     console.log("LitReference API called successfully");
        //     // setReferences(response.data.sourceObj);
        //     dispatch(setReferencesLLM(response.data.sourceObj));
        //   } catch (error) {
        //     console.error("Error calling LitReference API:", error);
        //   }
        // }

        if (
          data.data.error ===
          "No relevant papers found. Please try rephrasing your query."
        ) {
          setLitOpenDialog(true);
        }

        if (value === "web" && data.data.response === "Something went wrong!") {
          // Send error email if the response contains an error and it's the 'web' case
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Literature Review, ${selectedDomain}, ${selectedFormat}`,
            error: data.data.response,
            query: query,
          });
        } else if (value === "web" && data.data.error) {
          if (
            data.data.error ===
            "Literature reviews limit reached. Please upgrade your subscription for more."
          ) {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Literature Review, ${selectedDomain}, ${selectedFormat}`,
              error: data.data.error,
              query: query,
              limitReachedError: true,
            });
          } else {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Literature Review, ${selectedDomain}, ${selectedFormat}`,
              error: data.data.error,
              query: query,
            });
          }
        }

        dispatch(clearRefs());
      } catch (error) {
        clearTimeout(timeoutId);
        if (value === "web") {
          // Send error email if an actual error occurs only for 'web'
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: `Literature Review, ${selectedDomain},${selectedFormat}`,
            error: error.message,
            query: query,
          });
        }
      }
    }
  };

  const handleClear = () => {
    if (loading) {
      dispatch(clearLiterature(prTitle));
      dispatch(abortCancelFetch());
      // dispatch(clearLitReview(prTitle, { literature: "" }));
      // currPro.literature = "";
      console.log("Value of literature is", literature ? literature : "empty");
    }
    dispatch(clearLitReview(prTitle));
  };
  const handleCancelClick = () => {
    // dispatch(abortCancelFetch());
    let timer = localStorage.getItem("timer");
    clearInterval(timer);
    dispatch(clearLitReview(prTitle));
    setState((prev) => ({ ...prev, showAlert: false }));
    dispatch(clrProgress());
  };
  const handleContinue = () => {
    setState((prev) => ({ ...prev, showAlert: false }));
  };

  const handleChange = (event) => {
    // save the selected files
    const { value } = event.target;
    if (value.length > 5 && user.subscription.planName !== "pro") {
      Toast("error", "You can select maximum 5 files");
      return;
    }
    if (value.length > 15 && user.subscription.planName === "pro") {
      Toast("error", "You can select maximum 15 files");
      return;
    }
    //if selected files is less than 1
    if (value.length < 1) {
      Toast("error", "Please select atleast 1 file");
      return;
    }
    setSelectedFiles(value);
  };
  const getRatings = (rt) => {
    setState({ ...state, ratings: rt });
  };
  const SubmitFeedback = async () => {
    if (!state.ratings) {
      Toast("error", "Please provide a rating.");
      return;
    }

    let data = {
      email: localStorage.getItem("email"),
      feedback: {
        rating: state.ratings,
        date: moment().format("DD-MM-YYYY"),
        type: value,
        query: query,
        literatureReview: literature,
      },
    };

    await saveLiteratureRatings(data);
    Toast("success", "Thank you for your feedback", {
      icon: <ThumbUp />,
    });
  };

  const handlePasteReview = async () => {
    dispatch(setPasteContent(literature));
    let url = prTitle.toString().toLowerCase().replace(/\s+/g, "-");
    navigate(`/projects/${url}/document`);
  };

  const handleFormat = (e) => {
    const { value } = e.target;
    // setState((prev) => ({ ...prev, selectedFormat: value }));
    dispatch(setSelectedFormat(value));
  };
  const handleAdvanceFiltersToggle = () => {
    setShowAdvancedFilters((prev) => !prev);
  };

  const sources = ["library", "web"];

  return (
    <RootStyle id="literatureDialoge">
      <Box>
        <SearchField
          place="Write your research question here ..."
          val={query}
          onChange={handleInput}
          onClick={searchAbstracts}
          tooltip={true}
          load={loading && !literature}
          wordCount={query.trim().split(/\s+/).length}
          wordCounter={true}
        />
        <Stack direction={mediaQuery ? "column" : "row"} spacing={1}>
          <Box
            sx={{
              width: mediaQuery ? "100%" : value === "library" ? "50%" : "35%",
            }}
          >
            <FormControl size="small" sx={{ width: "100%", mt: "10px" }}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                }}
              >
                Sources
              </InputLabel>
              <Select
                size="small"
                value={value}
                label="Sources"
                onChange={handleRadio}
                sx={{
                  fontSize: "12px",
                  fontFamily: "Raleway",
                }}
                MenuProps={MenuProps}
                endAdornment={
                  <Tooltip
                    title={text}
                    placement="top"
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#353535",
                        fontSize: "18px",
                        marginRight: "14px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                }
              >
                {sources.map((source, i) => (
                  <MenuItem value={source} key={i}>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                          fontFamily: "Raleway",
                        }}
                      >
                        {source}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {value === "web" && (
            <Box
              sx={{
                width: mediaQuery ? "100%" : "35%",
              }}
            >
              <FormControl size="small" sx={{ width: "100%", mt: "11px" }}>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  Select Domain
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  // sx={{
                  //   width: mediaQuery ? "100%" : "50%",
                  //   background: "#F6F6F6",
                  //   borderRadius: "6px",
                  //   border: "none",
                  //   height: "32px",
                  //   "& fieldset": {
                  //     padding: "0",
                  //     margin: "0",
                  //     border: "none",
                  //   },
                  //   marginTop: "8px",
                  // }}
                  name="domain"
                  label="Select Domain"
                  value={domain}
                  disabled={value === "library"}
                  onChange={handleDomainInput}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Raleway",
                  }}
                  MenuProps={MenuProps}
                >
                  {Domains.map((domain, i) => (
                    <MenuItem
                      value={domain}
                      key={i}
                      disabled={
                        (domain === "Physics" && user.domain !== "Physics") ||
                        (domain === "Economics & Finance" &&
                          user.domain !== "Economics & Finance") ||
                        // (domain === "Chemistry" &&
                        //   user.domain !== "Chemistry") ||
                        (domain === "Psychology" &&
                          user.domain !== "Psychology")
                      }
                    >
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Raleway",
                          }}
                        >
                          {domain}
                        </Typography>
                        {/* {(domain == "Computer Science" ||
                          domain == "Medical & Health Sciences" ||
                          domain == "Humanities and Social Sciences" ||
                          domain == "Business Studies") && (
                          <Tooltip
                            sx={{
                              fontSize: "14px",
                            }}
                            title={
                              <Typography
                                fontSize={"12px"}
                                fontFamily={"Raleway"}
                              >
                                Currently our automated Literature Review and
                                Search is optimized for this domain.
                              </Typography>
                            }
                            placement="top"
                          >
                            <InfoOutlinedIcon
                              sx={{ color: "#353535", mr: 1, fontSize: "18px" }}
                            />
                          </Tooltip>
                        )} */}
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {value === "web" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: mediaQuery ? "100%" : "35%",
                }}
              >
                <FormControl size="small" sx={{ width: "100%", mt: "10px" }}>
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Format
                  </InputLabel>
                  <Select
                    size="small"
                    value={selectedFormat}
                    label="Format"
                    // disabled={value === "library"}
                    onChange={handleFormat}
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="short"
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Raleway",
                      }}
                    >
                      Short
                    </MenuItem>
                    <MenuItem
                      value="medium"
                      disabled={
                        !(
                          user.subscription.planName === "pro" ||
                          user.subscription.planName === "standard"
                        )
                      }
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Raleway",
                      }}
                    >
                      Medium{" "}
                      {!(
                        user.subscription.planName === "pro" ||
                        user.subscription.planName === "standard"
                      ) && " (Standard plan)"}
                    </MenuItem>
                    <MenuItem
                      value="long"
                      disabled={user.subscription.planName !== "pro"}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Raleway",
                      }}
                    >
                      Long
                      {user.subscription.planName !== "pro" && " ( Pro plan )"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  width: mediaQuery ? "100%" : "35%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    p: 0.7,
                    width: "100%",
                    background: "#1E4F6B",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#163C54",
                    },
                    mt: mediaQuery ? 1 : 0,
                    textTransform: "capitalize",
                    fontFamily: "Raleway",
                    fontSize: "13px",
                  }}
                  onClick={handleToggleDialog}
                >
                  See Guidelines
                </Button>
              </Box>

              <GuidelineDialog open={openDialog} onClose={handleToggleDialog} />
            </>
          )}
          {value === "library" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: mediaQuery ? "100%" : "50%",
                }}
              >
                <FormControl sx={{ mt: "10px", width: "100%" }} size="small">
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {`Select files upto ${
                      user.subscription.planName === "pro" ? "15" : "5"
                    }`}
                  </InputLabel>

                  <Select
                    label={`Select files upto ${
                      user.subscription.planName === "pro" ? "15" : "5"
                    }`}
                    multiple
                    fullWidth
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                    value={selectedFiles.length > 0 ? selectedFiles : []}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    size="small"
                  >
                    {filesData.map((name, i) => (
                      <MenuItem
                        key={i}
                        value={name.key}
                        sx={{
                          display: "flex",
                          fontSize: "12px",
                          fontFamily: "Raleway",
                        }}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  width: mediaQuery ? "100%" : "35%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    p: 0.7,
                    width: "100%",
                    background: "#1E4F6B",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#163C54",
                    },
                    mt: mediaQuery ? 1 : 0,
                    textTransform: "capitalize",
                    fontFamily: "Raleway",
                    fontSize: "13px",
                  }}
                  onClick={handleToggleDialog}
                >
                  See Guidelines
                </Button>
              </Box>
              <GuidelineDialog
                open={openDialog}
                onClose={handleToggleDialog}
                value={value}
              />
            </>
          )}
        </Stack>
        {/* {showAdvancedFilters && (
          <Stack direction={mediaQuery ? "column" : "row"} spacing={1}>
            {user.subscription.planName === "pro" && value === "web" && (
              <Box sx={{ width: mediaQuery ? "100%" : "50%" }}>
                <FormControl size="small" sx={{ width: "100%", mt: "10px" }}>
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Publication
                  </InputLabel>
                  <Select
                    size="small"
                    value={publicationNew}
                    disabled={!state.domain}
                    onChange={handlePublicationChange}
                    label="Publication"
                    inputProps={{
                      name: "publication",
                      id: "publication-select",
                    }}
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                    MenuProps={MenuProps}
                  >
                    {getPublications(state.domain).map((pub, i) => (
                      <MenuItem key={pub.key} value={pub.key}>
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontFamily: "Raleway",
                            }}
                          >
                            {pub.name}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {user.subscription.planName === "pro" && value === "web" && (
              <Box sx={{ width: mediaQuery ? "100%" : "50%" }}>
                <FormControl size="small" sx={{ width: "100%", mt: "11px" }}>
                  <InputLabel
                    sx={{ p: "0px 0px 0px 0px !important", fontSize: "14px" }}
                  >
                    Journals
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    sx={{
                      width: "100%",
                      p: "0px 0px 0px 0px",
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                    label="Journals"
                    value={selectedJournal}
                    multiple
                    onChange={handleJournalChange}
                    inputProps={{
                      name: "journal",
                      id: "journal-select",
                    }}
                    MenuProps={MenuProps}
                    renderValue={(selected) =>
                      selected.length > 0
                        ? selected
                            .map((key) => {
                              const journal = getJournals(
                                state.domain,
                                selectedPublication
                              ).find((journal) => journal.key === key);
                              return journal ? journal.name : null;
                            })
                            .filter(Boolean)
                            .join(", ")
                        : "Select Journals"
                    }
                  >
                    {getJournals(state.domain, selectedPublication).map(
                      (journal, index) => (
                        <MenuItem key={journal.key} value={journal.key}>
                          <Stack
                            direction="row"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Raleway",
                              }}
                            >
                              {journal.name}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Stack>
        )} */}
        <Stack
          direction={"row"}
          justifyContent={
            // user.subscription.planName === "pro" && value === "web"
            //   ? "space-between"
            //   :
            "flex-end"
          }
          mt={1}
          alignItems={"center"}
        >
          {/* {user.subscription.planName === "pro" && value === "web" && (
            <Button
              variant="contained"
              size="small"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              sx={{
                backgroundColor: "#353535 !important",
                textTransform: "none",
              }}
            >
              {showAdvancedFilters ? "Hide Filters" : "Advance Filters"}
            </Button>
          )} */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: "5px",
            }}
          >
            {!loading ? (
              <BtnClear onClick={handleClear} />
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={handleClear}
                sx={{
                  background: "#F2F2F2 !important",
                  textTransform: "none",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#868686",
                  boxShadow: "none !important",
                }}
              >
                <HighlightOffIcon fontSize="small" sx={{ mr: 1 }} />
                Stop
              </Button>
            )}
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: "5px",
            }}
          >
            {/* {loading ? (
              <Button
                variant="contained"
                size="small"
                onClick={handleClear}
                sx={{
                  background: "#F2F2F2 !important",
                  textTransform: "none",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#868686",
                  boxShadow: "none !important",
                }}
              >
                <HighlightOffIcon fontSize="small" sx={{ mr: 1 }} />
                Stop Generating
              </Button>
            ) : literature ? (
              <BtnClear onClick={handleClear} />
            ) : null} */}
            {literature && <BtnClear onClick={handleClear} />}
          </Box>
        </Stack>
      </Box>

      {!error === "" ||
        (loading && !literature && (
          <Stack sx={{ my: 2 }}>
            <TypeAnimation
              sequence={[` Generating Literature review: ......`, 2000]}
              wrapper="span"
              cursor={false}
              repeat={Infinity}
              className=" font-md sm:text-lg text-lg"
              style={{
                display: "inline-block",
                fontSize: "14px",
                color: "#353535",
              }}
            />
            <>
              <Loader />
            </>
          </Stack>
        ))}

      {!loading && literature && (
        <Box>
          <Text sx={{ fontSize: "16px", fontWeight: 600, color: "#14224B" }}>
            Literature Review :
          </Text>
          <Text sx={{ fontWeight: 400, color: "#353535CC" }}>
            <b>Note:</b> If you think the literature review below can be further
            improved, please try rephrasing your query. You can also provide
            feedback regarding the relevant research journals we should include
            in our database.
          </Text>
          <Box
            sx={{
              maxHeight: {
                md: "50vh",
                sm: "38vh",
                xs: "28vh",
              },
              overflowY: "auto",
              my: 1,
            }}
          >
            <Text sx={{ fontSize: "14px", mb: "10px" }}>
              {ReactHtmlParser(literature, {
                transform: (node, index) => {
                  if (node.type === "tag" && node.name === "a") {
                    node.attribs.style = "color: blue;";
                    node.attribs.target = "_blank";
                    node.attribs.rel = "noopener noreferrer";
                  }
                },
              })}
            </Text>
            {/* {lastString !== "" && value === "library" && (
              <Stack direction={"row"} textAlign={"center"}>
                <Text sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {lastString.replace("[0m", "")}
                </Text>
              </Stack>
            )} */}
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              rowGap: "8px",
            }}
          >
            <Grid item lg={2} sm={6} xs={12}>
              <Btn
                variant="contained"
                fullWidth
                size="small"
                onClick={handleShow}
              >
                View references
                {show ? (
                  <ArrowDropDownCircleOutlinedIcon sx={{ ml: 1 }} />
                ) : (
                  <PlayCircleOutlineIcon sx={{ ml: 1 }} />
                )}
              </Btn>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Ratings getRatings={(rt) => getRatings(rt)} />
                <Btn
                  onClick={SubmitFeedback}
                  size="small"
                  sx={{
                    height: "30px",
                  }}
                >
                  Submit
                </Btn>
              </Stack>
            </Grid>
            <Grid item lg={2.5} md={4} sm={6} xs={12}>
              <Stack alignItems="center" direction="row" spacing={1} pr={0.8}>
                <Btn
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={handlePasteReview}
                >
                  <ContentCopyIcon fontSize="small" sx={{ mx: 1 }} />
                  Paste in Editor
                </Btn>

                {/* <CopyButton text={literature} /> */}
                {/* <Tooltip placement="right-start">
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{
                        color: "#35353599",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#353535",
                        },
                      }}
                    />
                  </Tooltip> */}
              </Stack>
            </Grid>
          </Grid>
          {show && value === "web" && (
            <Box
              sx={{
                mt: 2,
                lineBreak: "anyWhere",
              }}
            >
              {sourceArr?.map((item, i) => (
                <Text
                  sx={{
                    fontSize: "14px",
                    // filter:
                    //   i >= 2 && user.subscription.planName !== "standard"
                    //     ? "blur(5px)"
                    //     : "none",

                    // userSelect:
                    //   user.subscription.planName !== "standard"
                    //     ? "none"
                    //     : "auto",
                    // color:
                    //   references[item] === "not_verified"
                    //     ? "red"
                    //     : references[item] === "verified"
                    //     ? "green"
                    //     : "black",
                  }}
                  key={i}
                >
                  {show && value === "web" && <span>{i + 1}. </span>}
                  {item}
                </Text>
              ))}
              {/* {sourceArr.length >= 2 &&
                  user.subscription.planName !== "standard" && (
                    <T
                    \ext
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center", // Text color for overlay
                      }}
                    >
                      Subscribe now to get full access : &nbsp;
                      <Btn
                        size="small"
                        sx={{
                          background: "#353535 !important",
                          color: "white",
                          "&:hover": { color: "white !important" },
                        }}
                        onClick={() => navigate("/subscription")}
                      >
                        Subscribe Now{" "}
                      </Btn>
                    </Text>
                  )} */}
            </Box>
          )}
          {show && value === "library" && (
            <Box
              sx={{
                mt: 2,
                lineBreak: "anyWhere",
              }}
            >
              {libraryReferences.map((item, i) => (
                <Text
                  sx={{
                    fontSize: "14px",
                    // filter:
                    //   i >= 2 && user.subscription.planName !== "standard"
                    //     ? "blur(5px)"
                    //     : "none",
                    // userSelect:
                    //   user.subscription.planName !== "standard"
                    //     ? "none"
                    //     : "auto",
                  }}
                  key={i}
                >
                  {item}
                </Text>
              ))}
            </Box>
          )}
        </Box>
      )}
      {!loading && !literature && (
        <ImgBox
          sx={{
            height: mediaQuery ? "50vh" : "68vh",
          }}
        >
          <img src={LitReviewImg} alt="" />
          <Text sx={{ fontWeight: 600, fontSize: "14px", color: "#515256" }}>
            No literature review generated !
          </Text>
        </ImgBox>
      )}
      <LitDialog open={litOpenDialog} onClose={handleLitDialogClose} />

      <Dialog onClose={handleContinue} open={state.showAlert} fullWidth>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <h1>
              Apologies for the delay. Your query is taking a bit longer than
              anticipated. Would you like to cancel it?
            </h1>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{ textTransform: "capitalize" }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            disabled={state.btn}
            sx={{ textTransform: "capitalize" }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}

export default LiteratureDialog;
