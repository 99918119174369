import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Divider,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "../../Analytics";
import {
  saveAbstracts,
  saveResearchSearchReference,
  saveReferenceNew,
  sendErrorMail,
} from "../../apiservice";
import magnifyingLoader from "../../assets/images/magnifyingLoader.gif";
import SearchHistory from "../../assets/images/search-history.svg";
import BtnClear from "../../components/BtnClear";
import SearchField from "../../components/SearchField";
import Toast from "../../components/Toast";
import { baseDomain } from "../../repo/Repository";
import AbstractDialogue from "../library/AbstractDialogue";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { clearRefs } from "../references/refsSlice";
import FeedbackDialogue from "../sidebar/FeedbackDialogue";
import {
  addPapers,
  addStatus,
  clearPaperQuery,
  clearPapers,
  handlePaperQuery,
  setLoading,
  setPageNo,
  resetPageNo,
  setDomain,
} from "./paperSlice";
import { BtnSave, ItemBox, RecomendationBtn, RootStyle, Title } from "./styles";
import { Check, InfoOutlined } from "@mui/icons-material";
import { Domains } from "../authentication/sign up/DomainsList";
import { clearUser } from "../Admin/userSlice";
import { clearAllInsights } from "../paper insights/insightsSlice";
import { clearAllLitReview } from "../literature review/reviewSlice";
import { clearProject } from "../projects/projectSlice";
import { getRefs } from "../references/refsSlice";
import SearchGuidelineDialog from "./SearchGuidelineDialog";
export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "12px",
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});
function ResearchpalPapers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery("(max-width: 768px)");
  const { paperQuery, papers, loading, saveStatus, page, domain } = useSelector(
    (state) => state.papers
  );
  const { user } = useSelector((state) => state.user);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedDoi, setSelectedDoi] = useState({});
  const [selectedAbstract, setSelectedAbstract] = useState(null);
  const [openSecondDialogue, setOpenSecondDialogue] = React.useState(false);
  const [openRecommendation, setOpenRecommendation] = React.useState(false);
  const [researchError, setResearchError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasPapers, setHasPapers] = useState(true);
  const location = useLocation();
  const [pageNo, setPage] = useState(1);
  console.log("saveStatus:", saveStatus);
  const userDomain = Domains.includes(user.domain) ? user.domain : "Other";
  // const [state, setState] = useState({
  //   // domain: user?.domain,
  //   domain: userDomain,
  //   // otherDomain: "",
  // });

  useEffect(() => {
    if (!domain) {
      dispatch(setDomain(userDomain));
    } // Initialize domain on component mount
  }, [dispatch, userDomain]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Papers",
    });
  }, []);

  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Ensure that text is not null or undefined
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  const RunScript = (currentPage = 1, loadMore = true) => {
    if (loadMore) {
      dispatch(clearPapers());
      setResearchError(false);
    }
    try {
      // if (page === 1) {
      //   dispatch(clearPapers());
      // }

      dispatch(setLoading(true));
      // let domain = state.domain;

      const timeoutId = setTimeout(() => {
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `ResearchPal Search, ${domain}`,
          error: "20 seconds",
          query: paperQuery,
          delayInResponse: true,
        });
      }, 20000);

      let info = {
        query: paperQuery,
        search: true,
        email: localStorage.getItem("email"),
        page: currentPage,
        domain: domain,
        token: localStorage.getItem("token") || "",
      };

      // if (arg) {
      //   info.source = arg;
      // }

      let requestOptions = {
        method: "POST",
        body: JSON.stringify(info),
        redirect: "follow",
      };
      fetch(`${baseDomain}/script`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          clearTimeout(timeoutId);
          let res = JSON.parse(result);
          // if (
          //   res.data.error ===
          //   "Invalid Session Id! You might be logged in from two different devices"
          // ) {
          //   Toast("error", res.data.error);
          //   dispatch(setLoading(false));
          //   // handleLogout();
          // }
          // console.log("result:", result);

          if (
            res.data.response === "" ||
            res.data.response === "Something went wrong!"
          ) {
            dispatch(setLoading(false));
            setResearchError(true); // Set error state here
            setHasPapers(false);

            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: `Researchpal Search, ${domain}`,
              error: res.data.response || "Empty Response",
              query: paperQuery,
            });

            return;
          }

          dispatch(addPapers(res.data.responseArr));
          dispatch(setLoading(false));
          setResearchError(false);
          setHasPapers(true);
          // dispatch(clearPaperQuery());
          // if (page === 3) {
          //   dispatch(setLoading(false));
          // }
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          //apply setTimeout to avoid infinite loading
          setTimeout(() => {
            dispatch(setLoading(false));
          }, 10000);

          // dispatch(setLoading(false));
        });
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };
  const handleSearch = () => {
    if (!paperQuery) {
      Toast("error", "Enter your query");
      return;
    }
    dispatch(setLoading(true));
    // setPage(1);
    dispatch(resetPageNo());
    setResearchError(false);
    // dispatch(setDomain(state.domain));
    dispatch(setDomain(domain));

    if (domain === "Computer Science") {
      RunScript(1, true);
    } else if (domain === "Medical & Health Sciences") {
      RunScript(1, true);
    } else if (domain === "Business Studies") {
      RunScript(1, true);
    } else if (domain === "Humanities and Social Sciences") {
      RunScript(1, true);
    } else if (domain === "Chemistry") {
      RunScript(1, true);
    } else {
      RunScript(1, true);
    }
  };
  const handleClear = () => {
    // dispatch(clearPaperQuery());
    // setPage(1);
    dispatch(resetPageNo());
    dispatch(clearPapers());
    setResearchError(false);
    dispatch(setLoading(false));
    setHasPapers(true);
  };

  const savePaper = async (item) => {
    const key = item.doi && item.doi !== "None" ? item.doi : item.title;
    // if (saveLoading && selectedDoi[item.doi]) {
    //   return;
    // }

    // setSaveLoading(true);
    // setSelectedDoi((prev) => ({ ...prev, [item.doi]: item.doi }));
    setSaveLoading((prev) => ({ ...prev, [key]: true }));
    setSelectedDoi((prev) => ({ ...prev, [key]: true }));

    let req = {
      saveReference: true,
      researchProjectId: localStorage.getItem("projectId"),
      title: item.title,
      doi: item.doi,
    };
    const referenceRes = await saveReferenceNew(req);
    console.log("referenceRes:", referenceRes);
    if (referenceRes?.data?.data?.sourceObj === "") {
      Toast("err", "NO data found");
      return;
    }

    if (referenceRes?.data?.data?.sourceObj) {
      let info = {
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        paper: {
          title: item.title,
          author: item.author,
          year: item.year,
          url: item.url,
          abstracts: item.abstracts,
          journal: item.journal,
          doi: item.doi,
          uploadDate: Date.now() / 1000,
        },
        reference: referenceRes?.data?.data?.sourceObj?.reference,
      };
      let res = await saveAbstracts(info);
      dispatch(getRefs(localStorage.getItem("projectId")));
      //for saving references
      // let req = {
      //   researchProjectId: localStorage.getItem("projectId"),
      //   doi: item.doi,
      // };

      dispatch(clearRefs());
      // setSaveLoading(false);
      setSaveLoading((prev) => ({ ...prev, [key]: false }));
      // let copy = JSON.parse(JSON.stringify(selectedDoi));
      // delete copy[item.doi];
      // setSelectedDoi(copy);
      setSelectedDoi((prev) => {
        let copy = { ...prev };
        delete copy[key];
        return copy;
      });
      if (res?.abstractSaved) {
        Toast("success", "Reference added & abstract saved in your library.");
        // setSaveStatus((prev) => ({ ...prev, [item.doi]: true }));
        // dispatch(addStatus({ ...saveStatus, [item.doi]: true }));
        dispatch(addStatus({ ...saveStatus, [key]: true }));
      }
    }
  };

  // const handleQuery = (e) => {
  //   const { value } = e.target;
  //   dispatch(handlePaperQuery(value));
  //   const words = value.trim().split(/\s+/);
  //   if (words.length > 60) {
  //     const trimmedValue = words.slice(0, 60).join(" ");
  //     dispatch(handlePaperQuery(trimmedValue));
  //   }
  // };

  const handleQuery = (e) => {
    const { value } = e.target;

    // Limit to a maximum of 450 characters
    let trimmedValue = value.length > 250 ? value.slice(0, 250) : value;

    // Prevent further typing
    e.target.value = trimmedValue;

    // Dispatch the trimmed input value
    dispatch(handlePaperQuery(trimmedValue));
  };

  // const handleQuery = (e) => {
  //   const { value } = e.target;

  //   // Split the input into words
  //   let words = value.trim().split(/\s+/);

  //   // If word count exceeds 60
  //   if (words.length > 60 || (words.length === 60 && words[59].length > 12)) {
  //     // Get the first 59 words
  //     const trimmedWords = words.slice(0, 59);

  //     // Trim the 60th word to a maximum of 12 characters
  //     const lastWord = words[59].slice(0, 12);

  //     // Join them together to form the trimmed input
  //     const trimmedValue = [...trimmedWords, lastWord].join(" ");

  //     // Prevent further typing
  //     e.target.value = trimmedValue;
  //     dispatch(handlePaperQuery(trimmedValue));
  //   } else if (value.length > 450) {
  //     // If the total character count exceeds 400, trim it
  //     const trimmedValue = value.slice(0, 450);

  //     // Prevent further typing
  //     e.target.value = trimmedValue;
  //     dispatch(handlePaperQuery(trimmedValue));
  //   } else {
  //     // Dispatch the input value if within the limits
  //     dispatch(handlePaperQuery(value));
  //   }
  // };

  const handleCloseSecondDialogue = () => {
    setOpenSecondDialogue(false);
  };
  const handleRecommendation = () => {
    setOpenRecommendation(true);
  };
  const handleCloseRecommendation = () => {
    setOpenRecommendation(false);
  };

  // const handleLoadMore = () => {
  //   setPage((prevPage) => {
  //     const newPage = prevPage + 1;
  //     RunScript(newPage, false);
  //     return newPage;
  //   });
  // };

  const handleLoadMore = () => {
    dispatch(setPageNo(page + 1));
    RunScript(page + 1, false);
  };

  // const handleDomainInput = (e) => {
  //   const { name, value } = e.target;
  //   setState((prev) => ({ ...prev, [name]: value }));
  // };

  const handleDomainInput = (e) => {
    const { value } = e.target;
    dispatch(setDomain(value)); // Dispatch action to update domain in Redux store
  };

  return (
    <RootStyle
      sx={{
        padding: { xs: "16px 8px 0px 8px", md: "16px 32px 0px 32px" },
        minHeight: "auto",
      }}
    >
      <SearchField
        place="Write your research topic here ..."
        val={paperQuery}
        onChange={handleQuery}
        load={loading}
        onClick={handleSearch}
        wordCount={paperQuery.trim().split(/\s+/).length}
        wordCounter={true}
      />

      <Stack
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        flexWrap={{ xs: "wrap", sm: "nowrap" }}
      >
        <Box
          sx={{ width: { xs: "100%", sm: "60%", md: "80%" }, display: "flex" }}
        >
          <FormControl size="small" sx={{ width: "100%", mt: "11px" }}>
            <InputLabel
              sx={{
                fontSize: "14px",
              }}
            >
              Select Domain
            </InputLabel>
            <Select
              fullWidth
              size="small"
              name="domain"
              label="Select Domain"
              MenuProps={{
                PaperProps: {
                  style: {
                    // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    // width: 250,

                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
              // value={state.domain}
              value={domain}
              onChange={handleDomainInput}
              sx={{
                fontSize: "12px",
                fontFamily: "Raleway",
              }}
              endAdornment={
                <Tooltip
                  sx={{
                    fontSize: "18px",
                    width: "100%",
                  }}
                  title={
                    <Typography fontSize={"12px"} fontFamily={"Raleway"}>
                      We have optimised our search for different domains based
                      on their most popular journals! As a lot of research is
                      interdisciplinary, changing the domain of your search may
                      give better results for some queries.
                    </Typography>
                  }
                  placement="top"
                >
                  <InfoOutlined
                    sx={{ color: "#353535", mr: 2, fontSize: "20px" }}
                  />
                </Tooltip>
              }
            >
              {Domains.map((domain, i) => (
                <MenuItem value={domain}>
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Raleway",
                      }}
                    >
                      {domain}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Box
            sx={{
              display: "flex",
              width: "4%",
              justifyContent: "center",
              alignItems: "center",
              mt: "15px",
              ml: "5px",
            }}
          >
            <Tooltip
              sx={{
                fontSize: "18px",
                width: "100%",
              }}
              title={
                <Typography fontSize={"12px"} fontFamily={"Raleway"}>
                  We have optimised our search for different domains based on
                  their most popular journals! As a lot of research is
                  interdisciplinary, changing the domain of your search may give
                  better results for some queries.
                </Typography>
              }
              placement="top"
            >
              <InfoOutlined sx={{ color: "#353535", mr: 1, fontSize: "20px" }} />
            </Tooltip>
          </Box> */}
        </Box>

        <Box
          sx={{ width: { xs: "100%", sm: "40%", md: "20%" }, display: "flex" }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleToggleDialog}
            sx={{
              background: "#1E4F6B",
              boxShadow: "none",
              "&:hover": {
                background: "#163C54",
              },
              textTransform: "capitalize",
              width: "100%",
              mt: 1.4,
              fontFamily: "Raleway",
            }}
          >
            See Guidelines
          </Button>

          <SearchGuidelineDialog
            open={openDialog}
            onClose={handleToggleDialog}
          />
        </Box>
      </Stack>
      {/* <Stack direction={mediaQuery ? "column" : "row"}>
          <Text
            sx={{
              fontWeight: 600,
              width: mediaQuery ? "100%" : "10%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "12px",
            }}
          >
            Select Domain :
          </Text>
          <Select
            fullWidth
            size="small"
            sx={{
              width: mediaQuery ? "100%" : "90%",
              background: "#F6F6F6",
              borderRadius: "6px",
              border: "none",
              height: "32px",
              "& fieldset": {
                padding: "0",
                margin: "0",
                border: "none",
              },
              marginTop: "8px",
            }}
            name="domain"
            value={state.domain}
            onChange={handleDomainInput}
          >
            {Domains.map((domain) => (
              <MenuItem value={domain}>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Raleway",
                    }}
                  >
                    {domain}
                  </Typography>
                  {(domain == "Computer Science" ||
                    domain == "Medical & Health Sciences" ||
                    domain == "Humanities and Social Sciences" ||
                    domain == "Business Studies") && (
                    <Tooltip
                      title={
                        <Typography fontSize={"12px"} fontFamily={"12px"}>
                          Currently our automated Literature Review and Search is
                          optimized for this domain.
                        </Typography>
                      }
                      placement="top"
                    >
                      <InfoOutlined
                        sx={{ color: "#353535", mr: 1, fontSize: "18px" }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </Stack> */}
      {/* {state.domain == "Other" && (
          <Stack justifyContent={"end"} direction={mediaQuery ? "column" : "row"}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <TextField
                fullWidth
                size="small"
                type="text"
                placeholder="Please Enter your domain"
                sx={{
                  width: mediaQuery ? "100%" : "100%",
                  fontSize: "12px !important",
                }}
                InputProps={{
                  style: {
                    padding: "0px 0px 0px 0px !important",
                    margin: "0px 0px 0px 0px !important",
                    fontFamily: "Raleway",
                    fontSize: "12px",
                  },
                }}
                name="otherDomain"
                value={state.otherDomain}
                onChange={handleDomainInput}
              />
            </FormControl>
          </Stack>
        )} */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Title
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#4E4E4E",
            mr: "8px",
          }}
        >
          We would love to hear your recommendations regarding the journals we
          should search for your domain
        </Title>

        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#353535 !important",
            textTransform: "none",
          }}
          onClick={handleRecommendation}
        >
          Add Recommendations
        </Button>
      </Box> */}

      {!loading && papers.length == 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: mediaQuery ? "50vh" : "70vh",
          }}
        >
          <img src={SearchHistory} alt="" />
          <Title
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#4E4E4E",
              my: 1,
            }}
          >
            {researchError
              ? "There was trouble fetching search results from ResearchPal API. Please try again!"
              : "No Search Results"}
          </Title>
        </Box>
      )}

      {papers.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Title sx={{ fontWeight: 400, fontFamily: "Roboto" }}>
            Your search results:
          </Title>

          <BtnClear onClick={handleClear} />
        </Stack>
      )}
      <Box
        sx={{
          height:
            papers.length > 0 || loading
              ? "65vh"
              : (papers.length > 0 || loading) && !mediaQuery
              ? "60vh"
              : "",
          overflowY: "auto",
          // "&::-webkit-scrollbar": {
          //   width: "16px", // Increase the width of the scrollbar
          // },
        }}
      >
        {papers.length > 0 &&
          papers.map((item) => {
            const key = item.doi && item.doi !== "None" ? item.doi : item.title;
            const isDoiNone = item.doi === "None";
            return (
              <Box
                key={key}
                sx={{
                  px: 2,
                  py: 3,
                  mr: 1,
                  background: "rgba(246, 246, 246, 0.7)",
                  border: "1.5px solid #D4D4D4",
                  my: 2,
                  cursor: "pointer",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setSelectedAbstract(item);
                  setOpenSecondDialogue(true);
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <DescriptionOutlinedIcon
                      sx={{ height: "20px", color: "#353535" }}
                    />
                    <Title sx={{ cursor: "pointer", fontSize: "14px" }}>
                      {item.title}
                    </Title>
                  </Stack>
                </Stack>

                <Box
                  sx={{ my: 2, background: "#EDEDED", borderRadius: "10px" }}
                >
                  <Box sx={{ p: 2 }}>
                    <Title
                      sx={{
                        cursor: "pointer",
                        fontSize: { xs: "10px", md: "12px" },
                      }}
                    >
                      {item?.tldr && item.tldr !== "None"
                        ? item.tldr
                        : truncateText(item.abstracts, 250) || item.abstracts}
                    </Title>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  mt={{ xs: 0.75, md: 2 }}
                  sx={{ px: 1, pb: 2 }}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      justifyContent={{ xs: "center", sm: "normal" }}
                    >
                      <Box
                        display={{ xs: "none", md: "flex" }}
                        alignItems="center"
                      >
                        <Text
                          sx={{
                            mb: { xs: 1, md: 0 },
                            maxWidth: { md: 200, lg: "lg" },
                          }}
                        >
                          {item.journal
                            ? truncateText(item.journal, 40)
                            : "N/A"}
                        </Text>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            mx: { xs: 1, sm: 1.5 },
                            mt: { xs: 1.5, md: 1 },
                            bgcolor: "grey.200",
                            height: { xs: 24, md: 18 },
                          }}
                        />
                      </Box>
                      <Text sx={{ mr: { xs: 1, sm: 1.5 } }}>
                        {item.author ? truncateText(item.author, 40) : "N/A"}
                      </Text>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          mr: { xs: 1, sm: 1.5 },
                          mt: { xs: 1.5, md: 1 },
                          bgcolor: "grey.200",
                          height: { xs: 24, md: 18 },
                        }}
                      />
                      <Text>{item.year}</Text>
                    </Box>
                  </Box>
                  <Box width={24} />
                </Box>

                <Divider />

                <Box sx={{ pt: 2, px: 1 }}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={{ xs: "12px", md: "0px" }}
                    alignItems="center"
                  >
                    <Stack spacing={{ xs: 0.3, sm: 0.8 }} direction="row">
                      <Title>DOI: </Title>
                      <Title sx={{ color: "#35353599" }}>{item?.doi}</Title>
                    </Stack>

                    <Stack direction="row" spacing="16px">
                      <Tooltip
                        title={
                          isDoiNone
                            ? "It seems like a review paper. Hence can't be saved"
                            : ""
                        }
                        arrow
                        disableHoverListener={!isDoiNone}
                      >
                        <Box>
                          <BtnSave
                            sx={{
                              width: "80px",
                              backgroundColor: saveStatus[key]
                                ? "#e2e2e2 !important"
                                : "initial",
                              "&:hover": {
                                backgroundColor: saveStatus[key]
                                  ? "#e2e2e2 !important"
                                  : "initial",
                              },
                              color: saveStatus[key]
                                ? "green !important"
                                : "#353535CC",
                            }}
                            disabled={isDoiNone || saveStatus[key]}
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              savePaper(item);
                            }}
                          >
                            {saveLoading[key] && selectedDoi[key] && (
                              <Stack
                                direction="row"
                                width="100%"
                                justifyContent="center"
                              >
                                <TailSpin
                                  height="15"
                                  width="15"
                                  color="#5AB7BF"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  visible={true}
                                />
                              </Stack>
                            )}
                            {(!saveLoading[key] || !selectedDoi[key]) && (
                              <>
                                {saveStatus[key] ? (
                                  <Check
                                    size="small"
                                    sx={{
                                      fontSize: "14px",
                                      mr: 1,
                                      color: "green",
                                    }}
                                  />
                                ) : (
                                  <AddIcon
                                    fontSize="small"
                                    sx={{ fontSize: "12px", mr: 1 }}
                                  />
                                )}
                              </>
                            )}
                            {!selectedDoi[key] && !saveStatus[key]
                              ? "Save"
                              : "Saved"}
                          </BtnSave>
                        </Box>
                      </Tooltip>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <BtnSave
                          variant="contained"
                          size="small"
                          sx={{ mt: -0.2 }}
                        >
                          <LanguageIcon sx={{ fontSize: "12px", mr: 1 }} />{" "}
                          Source
                        </BtnSave>
                      </a>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            );
          })}

        {papers.length > 0 && !loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 2,
            }}
          >
            {hasPapers ? (
              <RecomendationBtn
                sx={{
                  color: "#fff",
                  // background:
                  //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  // "&:hover": {
                  //   background:
                  //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  // },
                  background: "#1E4F6B",
                  "&:hover": {
                    background: "#1E4F6B",
                  },
                }}
                onClick={handleLoadMore}
              >
                Load More ...
              </RecomendationBtn>
            ) : (
              <Box
                sx={{
                  color: "#4E4E4E",
                  padding: "10px",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                No more Papers
              </Box>
            )}
          </Box>
        )}
        {papers.length > 0 && loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={magnifyingLoader} alt="" style={{ width: "50px" }} />
          </Box>
        )}
        {papers.length === 0 && loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                color: "#353535",
              }}
            >
              Fetching Papers...
            </Typography>
            <img src={magnifyingLoader} alt="" />
          </Box>
        )}
      </Box>
      <AbstractDialogue
        AbstractData={selectedAbstract}
        handleCloseSecondDialogue={handleCloseSecondDialogue}
        openSecondDialogue={openSecondDialogue}
      />
      <FeedbackDialogue
        openDialogue={openRecommendation}
        onClose={handleCloseRecommendation}
      />
    </RootStyle>
  );
}

export default ResearchpalPapers;
